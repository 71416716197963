/* src/pages/ContactPage.css */
.contact-container {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .contact-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .contact-container p {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .contact-container a {
    color: #034183;
    text-decoration: none;
  }
  
  .contact-container a:hover {
    text-decoration: underline;
  }
  