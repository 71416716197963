/* src/App.css */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

.footer {
  background-color: #f8f9fa; /* Same background color as navbar */
  padding: 10px 0; /* Reduce padding for the top and bottom */
  text-align: center; /* Center the content */
  width: 100%;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-logo {
  height: 30px; /* Adjust the height of the logo to make it smaller */
  margin-bottom: 5px; /* Reduce space between the logo and text */
}

.footer-text {
  font-size: 12px; /* Smaller font size for footer text */
  color: #6c757d; /* Muted color for text */
}

.footer-text .heart {
  color: #6c757d; /* Gray heart color */
}


.carousel-item img {
  max-height: 300px; /* Adjust the height as needed */
  object-fit: cover;
}

.carousel {
  margin-bottom: 20px; /* Add some space below the carousel */
}
