/* src/components/NavBar.css */
.navbar {
  font-family: 'Roboto', sans-serif; /* Set the custom font family */
  padding-top: 0rem; /* Reduce top padding */
  padding-bottom: 0rem; /* Reduce bottom padding */
}

.navbar-brand {
  padding-left: 0rem; /* Default left padding for the logo */
  margin-left: 0px; /* Remove left margin for the logo */
}

.navbar-brand img {
  height: 80px; /* Adjusted height for the logo */
  margin-top: -5px; /* Center the logo vertically */
  padding: -1 -1rem; /* Add padding around the logo, adjust as needed */
}

.navbar-nav .nav-link {
  font-weight: bold; /* Make the links bold */
  color: #000000; /* Change the color of the links */
  font-size: 18px; /* Adjust font size */
  padding: 0rem 0rem; /* Adjust padding for the links */
}

.navbar-nav .nav-link:hover {
  color: #034183; /* Change the color of the links on hover */
}

.navbar-nav .nav-item {
  margin-left: 0px; /* Add left margin to increase space between links */
  margin-right: 3px;
}

@media (max-width: 991px) {
  .navbar-brand {
    margin-bottom: 3px;
  }
  
  .navbar-nav {
    text-align: center; /* Center the nav items when collapsed */
  }
}
