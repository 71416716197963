.ncaa-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px; /* Increased space between conference boxes */
  padding: 20px;
}

.conference {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  width: 30%;
  max-width: 400px;
  min-width: 300px;
}

.conference-logo {
  width: 135px; /* 5% smaller than the original 150px */
  margin-bottom: 20px;
}

.conference-name {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
}

.teams {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.team-logo-link {
  display: inline-block;
}

.team-box {
  width: 61.75px; /* 5% smaller than the previous 65px */
  height: 61.75px; /* 5% smaller than the previous 65px */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f2f2f2; /* Darker gray background */
}

.team-logo {
  max-width: 42.75px; /* 5% smaller than the previous 45px */
  max-height: 38px; /* 5% smaller than the previous 40px */
}

.big-ten-logo {
  width: 128.25px; /* 5% smaller than the previous 135px */
  margin-bottom: 20px;
}
